import { useState, useEffect, useRef } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { RxTrackPrevious, RxTrackNext } from "react-icons/rx";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

import "../App.css";
import { css } from "../ConfigFiles/Config";

const ResponsiveTable = ({ columns, dataTable, filyterKeys, dataState ,showSearch,filters}) => {
   const firstRowRef = useRef(null);
  const [columns1, setColumns1] = useState([]);
  const [filterValues, setFilterValues] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsperpage] = useState(10);
  const [currentPosts, setCurrentposts] = useState([]);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const filterFunction = (fil) => {
     let f = fil?.toLowerCase()?.split(" ")?.join("");
    setFilterValues(dataTable.filter((e) => filyterKeys?.some((a) => (typeof (e[a]) == "string" ? e[a]?.toLowerCase() : e[a]?.toString())?.split(" ")?.join("")?.includes(f))));
    setCurrentPage(1);
  };
  const sorting = (item) => {
    if (item?.sortable == true) {
      let key = item?.key;
      let d = [...dataTable];
      item?.sorted == true
        ? d.sort((a, b) =>
          item?.type == "Number"
            ? b?.[key] - a?.[key]
            : item?.type == "Date"
              ? new Date(b?.[key]) - new Date(a?.[key])
              : b?.[key]?.localeCompare(a?.[key])
        )
        : d.sort((a, b) =>
          item?.type == "Number"
            ? a?.[key] - b?.[key]
            : item?.type == "Date"
              ? new Date(a?.[key]) - new Date(b?.[key])
              : a?.[key]?.localeCompare(b?.[key])
        );
      setFilterValues(d);
      setCurrentPage(1);
      let c = columns1?.map((e) => ({ ...e, sorted: item == e ? !e?.sorted : false }));
      setColumns1(c);
    }
  };

  var last = Math.ceil(filterValues?.length / postsPerPage);
  const goNext = () => {
    if (currentPage < last) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goPrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goFirst = () => {
    setCurrentPage(1);
  };

  const goLast = () => {
    setCurrentPage(last);
  };

  const changePostPerPage = (e) => {
    setPostsperpage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const setb = () => {
    last = Math.ceil(filterValues?.length / postsPerPage);
    setCurrentposts(filterValues?.slice(indexOfFirstPost, indexOfLastPost));
  };

  useEffect(() => {
    setb();
    firstRowRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  }, [currentPage, postsPerPage, dataTable, filterValues]);

  useEffect(() => {
    setFilterValues(dataTable);
  }, [dataTable]);

  useEffect(() => {
    setColumns1(columns?.map((e) => ({ ...e, sorted: false })));
  }, [columns]);
  return (
    <div className="bg bg-white h-100 border rounded">
      {showSearch&&<div className="col-md-12 d-flex position-absolut   mb-1 p-1">
        <div className="col-md-4">
        <input
          type="search"
          onChange={(e) => {
            filterFunction(e.target.value);
          }}
          className="form-control col-md-12 my-1 search-control search-bg"
          id
          placeholder="Search ..."
        />
        </div>
        <div className="ms-2">
        {filters}</div>
      </div>}
      <div style={{ height: "70vh", overflowY: "scroll" }}>
        {currentPosts?.length > 0 ? (
          <>
            <div className="d-flex justify-content-between text-white" style={{ padding: "10px", backgroundColor: css?.themeColor }}>
              {columns1 &&
                columns1.map((e, index) => (
                  <div className={`${e?.width ? e?.width : "col"} pointer fw-bold`} key={index} onClick={() => sorting(e)}>
                    {e?.name}
                  </div>
                ))}
            </div>
            <div className="" style={{ height: "64vh", overflowY: "scroll" }}>
              {currentPosts.map((e, i) => (
                <div key={i} className="d-flex justify-content-between" style={{ padding: "10px", backgroundColor: i % 2 == 0 ? "" : " #f3faff" }}>
                  {columns1?.map((c, j) => <div

                     className={`${c?.width ? c?.width : "col"}`} key={j}>{c?.selector?.(e, i) ? c?.selector?.(e, i) : "-" || e?.[c?.key] ? e?.[c?.key] : "-"}</div>)}
                </div>
              ))}
            </div>
            {/* <Table className="responsiveTable" ref={firstRowRef}>
              <Thead>
                <Tr>
                  {columns1 &&
                    columns1.map((item, index) => (
                      <Th className="pointer  " key={index} onClick={() => sorting(item)}>
                        {item?.name}
                       
                      </Th>
                    ))}
                </Tr>
              </Thead>
              <Tbody className="" style={{ height: "50vh", overflowY: "scroll" }}>
                {currentPosts.map((e, i) => (
                  <Tr key={i}>
                    {columns1?.map((c, j) => <Td
                      style={{ padding: "10px", backgroundColor: i % 2 == 0 ? "" : " #f3faff" }}
                      className=" tableresponsive   " key={j}>{c?.selector?.(e, i) ? c?.selector?.(e, i) : "-" || e?.[c?.key] ? e?.[c?.key] : "-"}</Td>)}
                  </Tr>
                ))}
              </Tbody>
            </Table> */}
          </>
        ) : (
          <div className="text-center p-2">No Data Found</div>
        )}
      </div>
      {currentPosts?.length > 0 && (
        <div className=" d-flex align-items-center justify-content-center py-2 text-white " style={{ background: css?.themeColor }}>
          <div>
            {" "}
            {(currentPage - 1) * postsPerPage + 1} -{" "}
            {filterValues?.length <= currentPage * postsPerPage ? filterValues?.length : currentPage * postsPerPage} of{" "}
            {filterValues?.length}{" "}
          </div>
          <div className="d-flex  col-md-3  justify-content-around align-items-center mobilepagination ">
            <RxTrackPrevious size="1.3rem" className="cursor-pointer pointer" title="First page" onClick={goFirst} />
            <GrFormPrevious size="1.5rem" className="cursor-pointer pointer" title="Previous page" onClick={goPrev} />
            <div className="h-100 ">
              <select className="form-select" onChange={changePostPerPage}>
                {[10, 20, 50, 100]?.map((e, i) => (
                  <option key={i} className="w-full bg-white">
                    {e}
                  </option>
                ))}
              </select>
            </div>
            <span> {currentPage} </span>
            <GrFormNext size="1.5rem" className="cursor-pointer pointer" title="Next page" onClick={goNext} />
            <RxTrackNext size="1.3rem" className="cursor-pointer pointer" title="Last page" onClick={goLast} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ResponsiveTable;
